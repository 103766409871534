@use '../../styles/mixins' as mixins;
@use '../../styles/breakpoints' as bp;
	
.photo-gallery-block {

	@include mixins.sectionStyles;
	
	.photo-gallery-block__content {
		@include mixins.grid(410px);

		@media only screen and (max-width: 1100px) {
			@include mixins.grid(300px);
		}
 
		// If last child is also the 3rd image or is just the 5th image (because 6th onwards won't show), otherwise, just do a standard grid
		.photo-gallery-image-module:last-child:nth-child(3),
		.photo-gallery-image-module:nth-child(5) {
			grid-column: 1 / -1;
		}

		// Restrict to 5 images in grid
		.photo-gallery-image-module:nth-child(1n + 6) {
			display: none;
		}

	}

	&.lightbox-enabled {
		
		.photo-gallery-lightbox {
			display: none;

			&.open {
				position: fixed;
				background: rgb(0 0 0 / 30%);
				z-index: 99999;
				top: 0;
				left: 0;
				width: 100vw;
				height: 100vh;
				padding: var(--wp--preset--spacing--80);
				display: flex;
				align-items: center;

				.gallery-track {

					display: flex;
					transition: 300ms;
					
					.photo-gallery-image-module {

						width: 100vw;
						position: relative;
						display: grid;
						place-content: center;

						&:nth-child(1), &:last-child {
							width: calc(100vw - (var(--wp--preset--spacing--80) * 2))
						}

						figure {
							max-width: calc(100% - (var(--wp--preset--spacing--80)* 2));
						}

					}

				}


				.lightbox-close {
					position: fixed;
					top: calc(var(--headerHeight) + var(--wp--preset--spacing--80));
    				right: calc(var(--wp--preset--spacing--80) / 2);
					height: 30px;
    				width: 40px;
					cursor: pointer;
					background: none;
					border: none;

					
					&::before, &::after {
						content: "";
						width: 21px;
						background: #fff;
						height: 2px;
						position: absolute;
					}

					&::before {
						transform: rotate(45deg);
					}

					&::after {
						transform: rotate(-45deg);
					}

				}

				.lightbox-nav {
					position: fixed;
					top: 50%;
					transform: translateY(-50%);
					left: 0;
					cursor: pointer;
					width: var(--wp--preset--spacing--80);
					height: 40px;
					background: none;
					border: none;

					&.lightbox-next {
						left: auto;
						right: 0;
						transform: translateY(-50%) rotate(180deg);
					}
					
					&::before, &::after {
						content: "";
						width: 21px;
						background: #fff;
						height: 2px;
						position: absolute;
						top: 50%;
					}
					
					&::before {
						transform: translateY(-50%) rotate(45deg) translateX(9px);
					}

					&::after {
						transform: translateY(-80%) rotate(-45deg) translateX(9px);
					}

					&.right {
						left: auto;
						right: calc(var(--wp--preset--spacing--80) / 2);
						transform: rotate(180deg);
					}

					&.hidden {
						display: none;
					}

				}
			}
		}
		
		.photo-gallery-block__content {

			.photo-gallery-image-module {
				cursor: pointer;
				position: relative;

				&:hover{
					figure::after {
						content: "";
						position: absolute;
						inset: 0;
						background-color: var(--wp--preset--color--bnpp-green-tint-20);
					}
				}

				&:active{
					figure::after {
						content: "";
						position: absolute;
						inset: 0;
						background-color: var(--wp--preset--color--bnpp-green-tint-30);
					}
				}

			}

		}


	}

}